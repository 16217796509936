// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/primitives/Tooltip.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/primitives/Tooltip.tsx");
  import.meta.hot.lastModified = "1720450759792.5554";
}
// REMIX HMR END

import * as React from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { cn } from "~/utils/cn";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
const variantClasses = {
  basic: "bg-background-bright border border-grid-bright rounded px-3 py-2 text-sm text-text-bright shadow-md fade-in-50",
  dark: "bg-background-dimmed border border-grid-bright rounded px-3 py-2 text-sm text-text-bright shadow-md fade-in-50"
};
const TooltipProvider = TooltipPrimitive.Provider;
const TooltipArrow = React.forwardRef(_c = ({
  ...props
}, ref) => <TooltipPrimitive.Arrow className="fill-popover z-50" {...props} />);
_c2 = TooltipArrow;
TooltipArrow.displayName = TooltipPrimitive.Arrow.displayName;
const Tooltip = React.forwardRef(_c3 = ({
  delayDuration = 0,
  ...props
}, ref) => <TooltipPrimitive.Root delayDuration={delayDuration} {...props} />);
_c4 = Tooltip;
Tooltip.displayName = TooltipPrimitive.Root.displayName;
const TooltipTrigger = TooltipPrimitive.Trigger;
const TooltipContent = React.forwardRef(_c5 = ({
  className,
  sideOffset = 4,
  variant = "basic",
  ...props
}, ref) => <TooltipPrimitive.Content ref={ref} sideOffset={sideOffset} className={cn("z-50 overflow-hidden animate-in data-[side=bottom]:slide-in-from-top-1 data-[side=left]:slide-in-from-right-1 data-[side=right]:slide-in-from-left-1 data-[side=top]:slide-in-from-bottom-1", variantClasses[variant], className)} {...props} />);
_c6 = TooltipContent;
TooltipContent.displayName = TooltipPrimitive.Content.displayName;
function SimpleTooltip({
  button,
  content,
  side,
  hidden,
  variant,
  disableHoverableContent = false,
  className,
  buttonClassName
}) {
  return <TooltipProvider disableHoverableContent={disableHoverableContent}>
      <Tooltip>
        <TooltipTrigger className={cn("h-fit", buttonClassName)}>{button}</TooltipTrigger>
        <TooltipContent side={side} hidden={hidden} className={cn("text-xs", className)} variant={variant}>
          {content}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>;
}
_c7 = SimpleTooltip;
export function InfoIconTooltip({
  content,
  buttonClassName,
  contentClassName,
  variant = "basic"
}) {
  return <SimpleTooltip button={<InformationCircleIcon className={cn("size-3.5 text-text-dimmed", buttonClassName)} />} content={content} variant={variant} className={contentClassName} />;
}
_c8 = InfoIconTooltip;
export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider, TooltipArrow, SimpleTooltip };
var _c, _c2, _c3, _c4, _c5, _c6, _c7, _c8;
$RefreshReg$(_c, "TooltipArrow$React.forwardRef");
$RefreshReg$(_c2, "TooltipArrow");
$RefreshReg$(_c3, "Tooltip$React.forwardRef");
$RefreshReg$(_c4, "Tooltip");
$RefreshReg$(_c5, "TooltipContent$React.forwardRef");
$RefreshReg$(_c6, "TooltipContent");
$RefreshReg$(_c7, "SimpleTooltip");
$RefreshReg$(_c8, "InfoIconTooltip");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;